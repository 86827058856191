import http from './http-common'
import store from '../store'
import GeneralAPI from '.'

/**
 * @param {apiInspections} Документ испытания
 */
export class apiInspections extends GeneralAPI {
  _basicAuth = ''
  constructor() {
    super()
    this._basicAuth = `Bearer ${store.getters.token}`
  }

  /**
   * Получить список записей
   */
  getAll(id) {
    return http
      .post(
        `/DocInspectionsDetails/list`,
        {
          page: 1,
          rows: 1000,
          filter: [
            {
              field: 'DocInspectionId',
              operator: 0,
              value: id
            }
          ]
        },
        {
          headers: {
            Authorization: this._basicAuth
          }
        }
      )
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Создать запись
   */
  create(data) {
    return http
      .post(`/DocInspectionsDetails`, data, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Изменить запись
   */
  update(data) {
    return http
      .put(`/DocInspectionsDetails`, data, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить запись
   */
  get(id) {
    return http
      .get(`/DocInspectionsDetails/${id}`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Удалить запись
   */
  delete(id) {
    return http
      .delete(`/DocInspectionsDetails/${id}`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
}

import Swal from 'sweetalert2'
import store from '../store'

export async function errorHandler(error) {
  if (error.response.status === 401) {
    store.dispatch('LogOut')
    location.reload()
  }

  const response = error.response.data
  const title = response.title || 'Error'
  let message = 'Unexpected error'

  const formatError = (name, value) => {
    let path = value.split('Path')[1]
    const error = value.split('Path')[0]
    path = 'Path ' + path
    return `<b>[${name}]</b> - <span style="color:red">${error}</span>${path}<br/>`
  }

  const formatErrors = (errors) => {
    let result = ''
    Object.entries(errors).forEach(([key, value]) => {
      value.forEach((msg) => {
        result += formatError(key, msg)
      })
    })
    return `<span style="font-size:.9rem">${result}</span>`
  }

  if (response.errors != null) {
    message = formatErrors(response.errors)
  } else if (response instanceof Blob) {
    try {
      const msg = JSON.parse(await response.text()).title
      if (msg) message = msg
    } catch {}
  }

  Swal.fire(title, message, 'error')
}

export default class GeneralAPI {
  async errorCatch(error) {
    await errorHandler(error)
  }
}

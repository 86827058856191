<template>
  <v-container class="container--fluid grid-list-md text-center">
    <v-row dense>
      <v-col cols="12">
        <app-widget title="Записи документа">
          <v-btn slot="widget-header-action" icon>
            <v-icon>mdi-flare</v-icon>
          </v-btn>
          <v-form slot="widget-content" ref="form" lazy-validation>
            <v-row class="row--form-item form-item--header">
              <div class="row--border col-form-5p pa-3">
                <p>№ п.п.</p>
              </div>
              <div class="row--border col-form-20p pa-3">
                <p>Методика испытаний /измерений</p>
              </div>
              <div class="row--border col-form-30p pa-3">
                <p>Определяемая характеристика (показатель)</p>
              </div>
              <div class="row--border col-form-45p">
                <v-row style="margin: 0">
                  <v-col cols="3" style="border-right: 1px solid black">
                    <p>Применяется</p>
                  </v-col>
                  <v-col cols="9">
                    <p>Результат испытаний</p>
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <v-row v-for="(formItem, index) in formArray" :key="formItem.uniqid" class="row--form-item">
              <div :class="'row--border col-form-5p pa-3 b-btn'">
                <p style="text-align: left">{{ index + 1 }}</p>
              </div>
              <div :class="'row--border col-form-20p pa-3 ' + borderValue(formItem.name, formArray[index - 1] ? formArray[index - 1].name : '')">
                <p style="text-align: left">{{ formItem.name }}</p>
              </div>
              <div :class="'row--border col-form-30p ' + borderValue(formItem.subClause, formArray[index - 1] ? formArray[index - 1].subClause : '')">
                <p style="text-align: left" class="pa-3" v-if="!formItem.subClause">{{ formItem.clause }}</p>
                <v-row v-if="formItem.subClause" class="fill-height ma-0">
                  <v-col class="row--border">
                    <p style="text-align: left">{{ formItem.clause }}</p>
                  </v-col>
                  <v-col>
                    <p style="text-align: left">{{ formItem.subClause }}</p>
                  </v-col>
                </v-row>
              </div>
              <app-group-document-item
                :class="'row--border col-form-45p b-btn'"
                :form-item="formItem"
                v-on:newValue="changeEvent"
                v-on:appliedChangeEvent="isAppliedChangeEvent"
                :index="index"
              ></app-group-document-item>
            </v-row>
          </v-form>
        </app-widget>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppWidget from '../../widget/AppWidget'
import { apiInspections } from '../../../api/api-inspections'
import AppGroupDocumentItem from './widget/AppGroupDocumentItem'

export default {
  name: 'DocumentRecords',
  components: {
    AppGroupDocumentItem,
    AppWidget
  },
  data: () => ({
    id: '',
    apiInspections: '',
    formArray: []
  }),
  created() {
    this.id = this.$route.params.id
    this.apiInspections = new apiInspections()
    this.initialize()
  },
  watch: {
    '$route.params.id': {
      handler(val) {
        if (val != null) {
          if (val !== this.id) {
            this.id = val
            console.log(this.id)
            this.initialize()
          }
        }
      },
      deep: true
    }
  },
  methods: {
    isAppliedChangeEvent(event) {
      console.log(event)
      this.isAppliedChange(event.v, event.index)
    },
    isAppliedChange(value, index) {
      this.formArray[index].isApplied = value
      this.change(this.formArray[index].value1, index, true)
    },
    async changeEvent(event) {
      await this.change(event.val, event.index, event.first, event.notSubmit)
    },
    async change(val, index, first, notSubmit) {
      let valCreate
      if (this.formArray[index].id == null) {
        first === true ? (this.formArray[index].value1 = val) : (this.formArray[index].value2 = val)
        if (!notSubmit) {
          valCreate = await this.apiInspections.create({
            claimId: this.formArray[index].claimId,
            gostValueId: this.formArray[index].gostValueId,
            value1: !first ? this.formArray[index].value1 : val,
            value2: first ? this.formArray[index].value2 : val,
            isApplied: this.formArray[index].isApplied
          })
          this.formArray[index].id = valCreate.data
        }
      } else {
        first === true ? (this.formArray[index].value1 = val) : (this.formArray[index].value2 = val)
        if (!notSubmit) {
          this.apiInspections.update({
            id: this.formArray[index].id,
            claimId: this.formArray[index].claimId,
            gostValueId: this.formArray[index].gostValueId,
            value1: !first ? this.formArray[index].value1 : val,
            value2: first ? this.formArray[index].value2 : val,
            isApplied: this.formArray[index].isApplied
          })
        }
      }
    },
    borderValue(value, newValue) {
      if (newValue != null) {
        if (value === newValue) {
          return ''
        } return 'b-btn'
      }
      return 'b-btn'
    },
    uniqid(prefix = '', random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000
      const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0')
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ''}`
    },
    async initialize() {
      this.formArray = []
      const response = await this.apiInspections.getAll(this.id)
      const formArray = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(response.data['data'])) {
        formArray.push({
          uniqid: this.uniqid(value.gostValueId + '_' + value.docInspectionId),
          clause: value.clause, // По факту лейбал
          name: value.name, // Наименования госта
          gostValueId: value.gostValueId, // Для ключей и для сохранения
          type1: value.type1, // Тип поля первого
          type2: value.type2, // Тип поля 2
          values1: value.values1, // варианты значения 1
          values2: value.values2, // варианты значения 2
          postfix1: value.postfix1, // значения сначала после инпутом 1
          postfix2: value.postfix2, // значения сначала после инпутом 1
          prefix1: value.prefix1, // значения сначала перед инпутом 1
          prefix2: value.prefix2, // значения сначала перед инпутом 2
          subClause: value.subClause, // разделитель
          value1: value.value1,
          value2: value.value2,
          claimId: value.claimId,
          docInspectionId: value.docInspectionId,
          isApplied: value.isApplied,
          id: value.id
        })
      }
      this.formArray = formArray
    }
  }
}
</script>

<style scoped lang="scss">
.form-item--header {
  p {
    text-align: center;
    height: 100%;
    margin-bottom: 0;
  }
}
.row--form-item {
  .b-top {
    border-top: black solid 1px;
  }
  .b-btn {
    border-top: black solid 1px;
  }
  .col-form-5p {
    width: 5%;
  }
  .col-form-10p {
    width: 10%;
  }
  .col-form-15p {
    width: 15%;
  }
  .col-form-20p {
    width: 20%;
  }
  .col-form-25p {
    width: 25%;
  }
  .col-form-30p {
    width: 30%;
  }
  .col-form-35p {
    width: 35%;
  }
  .col-form-40p {
    width: 40%;
  }
  .col-form-45p {
    width: 45%;
  }
  .row--border {
    border-right: black solid 1px;
  }
}
</style>
